import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'

import './scoped-style/BlogRoll.scss'
import './scoped-style/BlogSmallRoll.scss'
import { Const } from '../const/const'

class BlogSmallRoll extends React.Component {
  
  constructor() {
    super();
    this.state = {
      tag: '',
      selected: Const.TAG_LIST,
    }
  }

  clickTag(item) {
    const setValue = item === this.state.tag ? '' : item; 
    this.setState({
      tag: setValue
    })
  }
  
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    
    return (
      <div>
        {/* <div className="tags-area">
          Tags：
          {this.state.selected.map(( item, index) => (
            <button key={index} 
              className={"button is-rounded is-small" + (item === this.state.tag ? ' selected': '')} 
              onClick={this.clickTag.bind(this, item)}
            >{item}</button>
          ))}
        </div> */}
        <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6 width-triple" key={post.id}
              style={{
                display: this.state.tag === '' || post.frontmatter.tags.includes(this.state.tag) ? 'block' : 'none'
              }}
            > 
              <Link to={post.fields.slug}>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? 'is-featured' : ''
                  }`}
                >
                  <header className="item-header">
                    <p className="item-title">
                      <span className="has-text-weight-bold">{post.frontmatter.title}</span>
                    </p>
                    <div className="container">
                      <div className="wrap-small-img">
                        <img className="small-img" src={post.frontmatter.featuredimage.childImageSharp.fluid.src} alt=""></img>
                      </div>
                      <div className="post-meta">
                      {/* <span className="icon has-text-warning">
                        <i className="fas fa-home"></i>
                      </span> */}
                      <span className="is-block">
                          {post.frontmatter.date}
                      </span>
                      {post.frontmatter.tags.map((tag, index) => (
                              <span key={index} className="tag-font">#{tag} </span>
                          ))}
                      </div>
                    </div>
                  </header>
                </article>
              </Link>
            </div>
          ))}
        </div>
      </div>
      
    )
  }
}

BlogSmallRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogSmallRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }, featuredpost: {eq: true} } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY/M/D")
                featuredpost
                featuredimage {
                    childImageSharp {
                      fluid(maxWidth: 120, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogSmallRoll data={data} count={count} />}
  />
)
